@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;400;600;700&family=Source+Sans+Pro:wght@300;600;700&display=swap');

@layer base {
	body {
		@apply bg-[#061223] text-slate-50 font-Josefin_Sans;
	}
}

@layer utilities {
	.shape {
		clip-path: polygon(
			47% 0,
			71% 4%,
			89% 14%,
			96% 74%,
			82% 94%,
			59% 100%,
			16% 95%,
			0 20%
		);
	}
	.test {
		filter: drop-shadow(4px 4px 0px #4fb4f5);
	}
}
.swiper-button-next,
.swiper-button-prev {
	color: #2ee0d0 !important;
}

.swiper-pagination-bullet {
	width: 12px !important;
	height: 12px !important;
	background-color: #2ee0d0 !important;
}

.swiper-pagination-bullet-active {
	background-color: #2ee0d0 !important;
}
